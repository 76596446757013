<template>
  <div
    class="d-flex justify-content-between align-items-center p-3"
    :class="[hoverClass, qwarkBorder]"
  >
    <div>{{ frm.formation.description }}</div>
    <div class="text-success" v-if="frm.realisee && frm.reussi">
      Terminée
      <span v-if="questionsCounter > 0"> ({{ frm.poucentage_reussite }}%)</span>
    </div>
    <div class="text-danger" v-if="frm.realisee && !frm.reussi">
      Score {{ frm.poucentage_reussite }}%, {{ frm.pourcentage_requis }}% requis
    </div>
    <div
      class="text-primary cursor-pointer"
      v-if="!frm.realisee"
      @click="start"
    >
      Commencer
    </div>
    <div
      class="text-primary cursor-pointer"
      v-if="frm.realisee && !frm.reussi"
      @click="start"
    >
      Recommencer
    </div>
    <div class="formation-full-screen" v-background-3 v-if="show">
      <div class="formation-content pt-3 pl-1 pr-1 pl-md-5 pr-md-5">
        <diapo-1
          :image="diapo.image"
          :text="diapo.text"
          v-if="diapo.disposition == 1 && !diapo.questions"
        ></diapo-1>
        <diapo-2
          :image="diapo.image"
          :text="diapo.text"
          v-if="diapo.disposition == 2 && !diapo.questions"
        ></diapo-2>
        <diapo-3
          :image="diapo.image"
          :text="diapo.text"
          v-if="diapo.disposition == 3 && !diapo.questions"
        ></diapo-3>
        <diapo-4
          v-if="diapo.questions"
          :checked="checked"
          :success="success"
          :errors="errors"
          :diapo="diapo"
          :index="index"
          :total="total"
          :image="diapo.image"
          @prev="prev"
          @next="next"
          @stop="stop"
          @end="end"
          @check="check"
        ></diapo-4>
      </div>
      <tools-bar
        :questions="diapo.questions"
        :index="index"
        :description="frm.formation.description"
        :total="total"
        @prev="prev"
        @next="next"
        @stop="stop"
        @end="end"
      ></tools-bar>
    </div>
  </div>
</template>
<script>
import diapo1 from "@/components/profil/FormationsItem1.vue";
import diapo2 from "@/components/profil/FormationsItem2.vue";
import diapo3 from "@/components/profil/FormationsItem3.vue";
import diapo4 from "@/components/profil/FormationsItem4.vue";
import toolsBar from "@/components/profil/FormationsItemBar.vue";

export default {
  components: { diapo1, diapo2, diapo3, diapo4, toolsBar },
  data() {
    return {
      loading: false,
      feedback: {},
      show: false,
      index: 0,
      rep: {
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0
      },
      errors: 0,
      checked: false,
      success: false
    };
  },
  props: ["frm"],
  computed: {
    diapo: function() {
      return this.frm.contenu[this.index];
    },
    total: function() {
      return this.frm.contenu.length;
    },
    questionsCounter: function() {
      return this.frm.contenu.filter(f => f.questions == true).length * 4;
    },
    hoverClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    },
    qwarkBorder: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    }
  },
  methods: {
    start: function() {
      window.scrollTo({ top: 0 });
      document.body.classList.add("modal-open");
      this.show = true;
      this.index = 0;
      this.feedback = {};
      this.checked = false;
      this.success = false;
      this.rep = { q1: 0, q2: 0, q3: 0, q4: 0 };
      this.errors = 0;
      this.frm.note = 0;
    },
    stop: function() {
      document.body.classList.remove("modal-open");
      this.show = false;
      this.index = 0;
      this.feedback = {};
      this.checked = false;
      this.success = false;
      this.rep = { q1: 0, q2: 0, q3: 0, q4: 0 };
      this.errors = 0;
    },
    end: function() {
      document.body.classList.remove("modal-open");
      this.show = false;
      this.index = 0;
      this.feedback = {};
      this.checked = false;
      this.success = false;
      this.rep = { q1: 0, q2: 0, q3: 0, q4: 0 };
      this.errors = 0;
      this.frm.realisee = true;
      this.updateFrmRealisee();
      this.$emit("end");
    },
    prev: function() {
      this.rep = { q1: 0, q2: 0, q3: 0, q4: 0 };
      this.errors = 0;
      this.feedback = {};
      this.checked = false;
      this.success = false;
      if (this.index > 0) this.index = this.index - 1;
    },
    next: function() {
      this.rep = { q1: 0, q2: 0, q3: 0, q4: 0 };
      this.errors = 0;
      this.feedback = {};
      this.checked = false;
      this.success = false;
      if (this.index < this.total) this.index = this.index + 1;
    },
    check: function(rep) {
      this.errors = 0;
      this.feedback = {};
      this.checked = false;
      this.success = false;

      if (rep.q1 != this.diapo.r1) {
        this.feedback.q1 = "Faux";
        this.errors++;
      }
      if (rep.q2 != this.diapo.r2) {
        this.feedback.q2 = "Faux";
        this.errors++;
      }
      if (rep.q3 != this.diapo.r3) {
        this.feedback.q3 = "Faux";
        this.errors++;
      }
      if (rep.q4 != this.diapo.r4) {
        this.feedback.q4 = "Faux";
        this.errors++;
      }

      this.frm.note = this.frm.note + 4 - this.errors;
      if (this.errors == 0) this.success = true;
      this.checked = true;
    },
    updateFrmRealisee: function() {
      this.loading = true;
      this.$http
        .post("/mooc-formations-realisee", {
          id: this.frm.id,
          realisee: true,
          note: this.frm.note
        })
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {}
};
</script>
<style>
.formation-full-screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  overflow: auto;
}

.formation-content {
  position: relative;
  padding: 0rem;
  display: flex;
  z-index: 9001;
  padding-bottom: 90px;
  min-height: 100%;
}

.img {
  margin: auto;
  width: 100%;
  max-width: 1200px;
  max-height: 50vh;
  min-height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.img2 {
  width: 100%;
  height: 70vh;
  min-height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.img3 {
  margin: auto;
  width: 40%;
  height: 70vh;
  min-height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

li p {
  text-align: left !important;
  margin-bottom: 0.3rem !important;
}

blockquote {
  border-left: 3px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  padding-left: 0.8rem;
  font-style: italic;
  margin: 1rem 0;
}

blockquote p {
  margin: 0;
}

ol,
ul {
  padding-left: 1rem;
}
</style>
