<template>
  <div class="w-100 rounded mr-3 mb-3" v-background-3>
    <div class="p-3" :class="qwarkBorder">
      <h5 class="m-0">Formations</h5>
      <span class="text-muted"><small> MOOC du groupe ARKADIA </small></span>
    </div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <div v-if="!loading" class="">
      <div class="" v-for="frm in formations" :key="frm.id">
        <formations-item :frm="frm" @end="getFormations"></formations-item>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/bases/Loader.vue";
import FormationsItem from "@/components/profil/FormationsItem.vue";
export default {
  components: {
    FormationsItem,
    Loader
  },
  data() {
    return {
      formations: [],
      loading: false,
      feedback: {}
    };
  },
  computed: {
    qwarkBorder: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    }
  },
  methods: {
    getFormations: function() {
      this.loading = true;
      this.$http
        .get("/my-formations")
        .then(response => (this.formations = response.data))
        .finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.getFormations();
  }
};
</script>
<style></style>
